<template>
  <div
    class="modal fade"
    id="modalGoodsVendor"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Tambah Daftar Vendor</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group d-flex align-items-center">
              <label for="inputAddress2" class="form-label">Vendor </label>
              <Select2
                style="width: 100%"
                v-model="formVendor.id_vendor"
                :options="optionVendor"
                placeholder="Pilih Vendor"
                :settings="{ allowClear: false, templateResult: resultVendor }"
                @change="barangChange('id_vendor', $event)"
                @select="barangChange('id_vendor', $event)"
                :class="{
                  'is-invalid': formErrorVendor && formErrorVendor.id_vendor,
                }"
              />
            </div>

            <div class="form-group d-flex align-items-center">
              <label class="form-label">Harga </label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">Rp</div>
                </div>
                <input
                  type="text"
                  v-model="formVendor.harga"
                  v-money="configMoney"
                  class="form-control text-right"
                  placeholder="Masukkan Harga"
                  @keyup="barangChange('harga', $event)"
                  :class="{
                    'is-invalid': formErrorVendor && formErrorVendor.harga,
                  }"
                />
              </div>
            </div>

            <div class="form-group d-flex align-items-center">
              <label class="form-label">Satuan </label>
              <div class="input-group">
                <!-- <div class="input-group-prepend">
                  <span
                    class="input-group-text"
                    style="border-radius: 0; background: #e9ecef"
                    >/</span
                  >
                </div> -->

                <input
                  type="text"
                  class="form-control text-right"
                  placeholder="ex. 2000"
                  @keyup="barangChange('satuan_jumlah', $event)"
                  v-maska="'#########'"
                  :class="{
                    'is-invalid':
                      formErrorVendor && formErrorVendor.satuan_jumlah,
                  }"
                  v-model="formVendor.satuan_jumlah"
                />
                <input
                  type="text"
                  class="form-control"
                  placeholder="ex. m2"
                  v-model="formVendor.satuan_nama"
                  :class="{
                    'is-invalid':
                      formErrorVendor && formErrorVendor.satuan_nama,
                  }"
                  @keyup="barangChange('satuan_nama', $event)"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" @click="saveBarang" class="btn btn-save">
              Tambah Data
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="modalGoodsOut"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Barang Sewa Keluar</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group d-flex align-items-center">
              <label for="inputAddress2" class="form-label">Gudang </label>
                <input
                  type="text"
                  v-model="formSewa.nama_gudang"
                  class="form-control"
                  readonly="true"
                />
            </div>
            <div class="form-group d-flex align-items-center">
              <label for="inputAddress2" class="form-label">Penangung Jawab </label>
                <input
                  type="text"
                  v-model="formSewa.nama_karyawan"
                  class="form-control"
                  readonly="true"
                />
            </div>

            <div class="form-group d-flex align-items-center">
              <label for="inputAddress2" class="form-label">Tanggal terima </label>
                <input
                  type="text"
                  v-model="formSewa.tanggal_terima"
                  class="form-control"
                  readonly="true"
                />
            </div>
            <div class="form-group d-flex align-items-center">
              <label for="inputAddress2" class="form-label">Jumlah </label>
                <input
                  type="text"
                  v-model="formSewa.qty"
                  class="form-control"
                  readonly="true"
                />
            </div>

            <div class="form-group d-flex align-items-center">
              <label for="inputAddress2" class="form-label">Tanggal Keluar <span class="text-danger">*</span> </label>
              <div>
                <Datepicker
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    :autoApply="true"
                    :closeOnScroll="true"
                    v-model="formSewa.tanggal_keluar"
                    placeholder="Tanggal Keluar"
                    :format="format"
                    :enableTimePicker="false"
                    locale="id-ID"
                    selectText="Pilih"
                    cancelText="Batal"
                    :class="{
                      'is-invalid': errorSewa,
                    }"
                    @update:modelValue="
                      changeDate($event, 'tanggal_keluar')
                    "
                  ></Datepicker>
              <div class="form-error" v-if="errorSewa">{{ errorSewa }}</div>
              </div>
            </div>
            
          </div>
          <div class="modal-footer">
            <button type="button" @click="setBarangOut" :disabled="isLoadSewa" class="btn btn-save">
              <span class="fas fa-spinner fa-spin mr-2" v-if="isLoadSewa"></span>
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">Edit Barang</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-barang">Barang </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Edit
                  </li>
                </ol>
              </nav>
            </div>
            <div class="button-edit" v-show="isEditable == 1">
              <button class="btn-add" type="button" @click="editable">
                <img src="/img/icon-edit1.svg" alt="" style="width: 20px" />
                Edit
              </button>
            </div>
            <div v-show="isEditable != 1">
              <button class="btn btn-save" :disabled="isSubmit">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>

          <div class="card-table info-detail">
            <img
              :src="showImage(formData.barcode)"
              alt=""
              class="img-barcode"
            />
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  id="pills-home-tab"
                  data-toggle="pill"
                  href="#pills-home"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                  >Detail</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="pills-profile-tab"
                  data-toggle="pill"
                  href="#pills-profile"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                  >History Stok</a
                >
              </li>

              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="pills-PO-tab"
                  data-toggle="pill"
                  href="#pills-PO"
                  role="tab"
                  aria-controls="pills-PO"
                  aria-selected="false"
                  >History PO</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="pills-location-tab"
                  data-toggle="pill"
                  href="#pills-location"
                  role="tab"
                  aria-controls="pills-location"
                  aria-selected="false"
                  >Lokasi</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="pills-sewa-tab"
                  data-toggle="pill"
                  href="#pills-sewa"
                  role="tab"
                  aria-controls="pills-sewa"
                  aria-selected="false"
                  >Sewa</a
                >
              </li>
            </ul>

            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="title">Detail Barang</div>
                    <div class="sub-title">Edit informasi yang diperlukan</div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <div class="form-group">
                      <label>Kode Barang :</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan Kode Barang"
                        v-model="formData.kode"
                        disabled
                      />
                    </div>
                    <div class="form-group">
                      <label>Kategori :</label>
                      <Select2
                        :disabled="true"
                        v-model="formData.kategori"
                        :class="{
                          'is-invalid': formError && formError.kategori,
                        }"
                        :options="optionCategory"
                        placeholder="Pilih Kategori"
                        @change="formChange('kategori', $event)"
                        @select="formChange('kategori', $event)"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.kategori"
                      >
                        {{ formError.kategori }}
                      </div>
                    </div>

                    <div class="form-group">
                      <label>Jenis :</label>
                      <Select2
                        :disabled="isEditable == 1"
                        v-model="formData.jenis"
                        :class="{ 'is-invalid': formError && formError.jenis }"
                        :options="optionType"
                        placeholder="Pilih Jenis Barang"
                        @change="formChange('jenis', $event)"
                        @select="formChange('jenis', $event)"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.jenis"
                      >
                        {{ formError.jenis }}
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="">Harga Pagu :</label>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control text-right"
                          placeholder="Masukkan Harga Pagu"
                          :disabled="isEditable == 1"
                          @select="formChange('pagu', $event)"
                          :class="{ 'is-invalid': formError && formError.pagu }"
                          v-money="configMoney"
                          v-model="formData.pagu"
                        />
                        <div class="input-group-append">
                          <Select2
                            v-model="formData.satuan"
                            :disabled="isEditable == 1"
                            :class="{
                              'is-invalid': formError && formError.satuan,
                            }"
                            :options="optionUnit"
                            placeholder="Pilih Satuan"
                            @change="formChange('satuan', $event)"
                            @select="formChange('satuan', $event)"
                          />
                        </div>
                      </div>
                      <div
                        class="form-error"
                        v-if="formError && (formError.satuan || formError.pagu)"
                      >
                        {{ formError.pagu ? formError.pagu : formError.satuan }}
                      </div>
                      <input
                        v-if="formData.satuan == 'new'"
                        type="text"
                        class="form-control mt-3"
                        placeholder="Masukkan Satuan"
                        v-model="formData.nama_satuan"
                        :class="{
                          'is-invalid': formError && formError.nama_satuan,
                        }"
                        @keyup="formChange('nama_satuan')"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.nama_satuan"
                      >
                        {{ formError.nama_satuan }}
                      </div>
                    </div>

                    <div class="form-group">
                      <label>Akun Barang :</label>
                      <Select2
                        v-model="formData.akun_barang"
                        :class="{
                          'is-invalid': formError && formError.akun_barang,
                        }"
                        disabled="true"
                        :settings="{ templateResult: formatState }"
                        :options="optionAkunGoods"
                        placeholder="Pilih Akun Barang"
                        @change="formChange('akun_barang', $event)"
                        @select="formChange('akun_barang', $event)"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.akun_barang"
                      >
                        {{ formError.akun_barang }}
                      </div>
                    </div>

                    <div class="form-group">
                      <label>Akun Beban Sewa :</label>
                      <Select2
                        v-model="formData.akun_sewa"
                        :class="{
                          'is-invalid': formError && formError.akun_sewa,
                        }"
                        :disabled="isEditable == 1"
                        :settings="{ templateResult: formatState }"
                        :options="optionAkunRental"
                        placeholder="Pilih Akun Beban Sewa"
                        @change="formChange('akun_sewa', $event)"
                        @select="formChange('akun_sewa', $event)"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.akun_sewa"
                      >
                        {{ formError.akun_sewa }}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6">
                    <div class="form-group">
                      <label>Nama Barang :</label>
                      <input
                        :disabled="isEditable == 1"
                        type="text"
                        class="form-control"
                        placeholder="Masukkan Nama Barang"
                        v-model="formData.nama"
                        :class="{ 'is-invalid': formError && formError.nama }"
                        @keyup="formChange('nama')"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.nama"
                      >
                        {{ formError.nama }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Departemen :</label>
                      <Select2
                        v-model="formData.id_divisi"
                        :class="{
                          'is-invalid': formError && formError.id_divisi,
                        }"
                        :options="optionDepartement"
                        disabled="true"
                        placeholder="Pilih Departemen"
                        @change="formChange('id_divisi', $event)"
                        @select="formChange('id_divisi', $event)"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.id_divisi"
                      >
                        {{ formError.id_divisi }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="">Keterangan :</label>
                      <textarea
                        :disabled="isEditable == 1"
                        type="text"
                        class="form-control"
                        placeholder="Masukkan Keterangan"
                        v-model="formData.keterangan"
                      />
                    </div>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-6">
                    <div class="title">Daftar Vendor</div>
                    <div class="sub-title">
                      Daftar vendor yang menyediakan barang
                    </div>
                  </div>

                  <div class="col-6 text-right">
                    <button
                      class="btn-add btn"
                      type="button"
                      @click="showGoodsVendor"
                      v-if="isEditable != 1"
                    >
                      Tambah
                    </button>
                  </div>
                </div>
                <hr />
                <div class="">
                  <table
                    class="table table-bordered table-striped mt-2"
                    id="tableListVendor"
                  >
                    <thead>
                      <tr>
                        <th style="width: 20%">Vendor</th>
                        <th style="width: 15%">Harga</th>
                        <th style="width: 20%">Satuan</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(value, index) in listVendor" :key="index">
                        <td>
                          {{ value.nama_vendor }}
                        </td>
                        <td class="text-right">
                          {{ formatMoney(value.harga) }}
                        </td>
                        <td class="position-relative">
                          {{ `${value.satuan_jumlah} ${value.satuan_nama}` }}
                          <div
                            style="
                              position: absolute;
                              right: -30px;
                              top: 50%;
                              transform: translateY(-50%);
                            "
                          >
                            <img
                              v-if="isEditable != 1"
                              src="/img/avatar/icon-delete.svg"
                              @click="deleteBarang(index)"
                              class="btn-add-row ml-1"
                              style="cursor: pointer; width: 20px"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div class="d-flex justify-content-end">
                  <div class="card-total">
                    <div
                      class="total-debit d-flex flex-column mt-4"
                      style="margin-right: 0px"
                    >
                      <span>Total Stok</span>
                      <span class="ml-auto">
                        {{
                          this.totalInventory ? this.totalInventory : 0
                        }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <table
                    class="table table-bordered table-striped mt-5"
                    id="tableHistory"
                  >
                    <thead>
                      <tr>
                        <th style="width: 20%">Tanggal</th>
                        <th style="width: 15%">No. Refrensi</th>
                        <th style="width: 20%">Qty</th>
                        <th style="width: 10%">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(value, index) in listInventory" :key="index">
                        <td>
                          {{
                            formatDate(value.created_at, "DD MMMM YYYY HH:mm")
                          }}
                        </td>
                        <td>{{ value.no_referensi }}</td>
                        <td>{{ value.qty }}</td>
                        <td style="width: 20%">
                          <div class="red-bedge" v-if="value.tipe == 'keluar'">
                            Keluar
                          </div>
                          <div
                            class="green-bedge"
                            v-else-if="value.tipe == 'masuk'"
                          >
                            Masuk
                          </div>
                          <div class="orange-bedge" v-else>Stock Opname</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="pills-PO"
                role="tabpanel"
                aria-labelledby="pills-PO-tab"
              >
                <div class="row align-items-center mt-3">
                  <div class="col-6">
                    <div class="title">History PO Barang</div>
                    <div class="sub-title">
                      Rincian dari PO barang yang diperlukan
                    </div>
                  </div>

                  <div class="col-6 text-right">
                    <button class="btn btn-add" type="button" @click="applyPO">
                      Ajukan PO
                    </button>
                  </div>
                </div>
                <hr />
                <div class="table-responsive mt-4">
                  <table
                    class="table table-bordered table-striped"
                    id="tablePO"
                  >
                    <thead>
                      <tr>
                        <th style="width: 20%">No. PO</th>
                        <th style="width: 20%">Tanggal</th>
                        <th style="width: 20%">Qty</th>
                        <th style="width: 20%">Harga Satuan</th>
                        <th style="width: 15%">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(value, index) in listPO" :key="index">
                        <td>{{ value.kode }}</td>
                        <td>
                          {{
                            formatDate(value.tanggal_req_beli, "DD MMMM YYYY")
                          }}
                        </td>
                        <td class="text-right">{{ value.qty }}</td>
                        <td class="text-right">
                          {{ formatMoney(value.harga_satuan) }}
                        </td>
                        <td style="width: 13%">
                          <div
                            class="red-bedge"
                            v-if="value.status == 'menunggu persetujuan'"
                          >
                            Menunggu Persetujuan
                          </div>
                          <div
                            class="orange-bedge"
                            v-else-if="value.tanggal_po && value.status == 'diproses'"
                          >
                            Diproses
                          </div>
                          <div
                            class="orange-bedge"
                            v-else-if="
                              value.tanggal_po && value.status == 'diterima sebagian'
                            "
                          >
                            Diterima Sebagian
                          </div>
                          <div
                            class="red-bedge text-capitalize"
                            v-else-if="value.status != 'selesai'"
                          >
                            {{ value.status }}
                          </div>
                          <div class="green-bedge" v-else>Selesai</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              
              <div
                class="tab-pane fade"
                id="pills-location"
                role="tabpanel"
                aria-labelledby="pills-location-tab"
              >
                <div class="row align-items-center mt-3">
                  <div class="col-12">
                    <div class="title">Informasi Lokasi</div>
                    <div class="sub-title">Edit informasi yang diperlukan</div>
                  </div>
                </div>
                <hr />
                <div class="table-responsive mt-4">
                  <table
                    class="table table-bordered table-striped"
                    id="tableLocation"
                  >
                    <thead>
                      <tr>
                        <th style="width: 15%">Gudang</th>
                        <th style="width: 30%">Alamat</th>
                        <th style="width: 30%">Jumlah</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(value, index) in listLokasi" :key="index">
                        <td>{{ value.nama_gudang }}</td>
                        <td>
                          {{ value.alamat }}
                        </td>
                        <td class="text-right">{{ value.jumlah }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="pills-sewa"
                role="tabpanel"
                aria-labelledby="pills-sewa-tab"
              >
                <div class="row align-items-center mt-3">
                  <div class="col-12">
                    <div class="title">Informasi Sewa</div>
                    <!-- <div class="sub-title">Edit informasi yang diperlukan</div> -->
                  </div>
                </div>
                <hr />
                <div class="table-responsive mt-4">
                  <table
                    class="table table-bordered table-striped"
                    id="tableLocation"
                  >
                    <thead>
                      <tr>
                        <th style="width: 20%">Gudang</th>
                        <th style="width: 20%">Penangung Jawab</th>
                        <th style="width: 15%">Tanggal Terima</th>
                        <th style="width: 15%">Tanggal Keluar</th>
                        <th style="width: 15%">Jumlah</th>
                        <th style="width: 10%">Status</th>
                        <th style="width: 7%">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(value, index) in listSewa" :key="index">
                        <td>{{ value.nama_gudang ? value.nama_gudang : '-' }}</td>
                        <td>{{ value.nama_karyawan ? value.nama_karyawan : '-' }}</td>
                        <td>{{ formatDate(value.tanggal_terima) }}</td>
                        <td>{{ value.tanggal_keluar ? formatDate(value.tanggal_keluar) : '-' }}</td>
                        <td class="text-right">
                          {{ value.qty }}
                        </td>
                        <td>
                          <div class="green-bedge" v-if="value.status == 'aktif'">
                            Aktif
                          </div>
                          <div class="red-bedge" v-else>Tidak</div>
                        </td>
                        <td>
                          <div data-toggle="tooltip" @click="barangOut(value)" v-if="value.status == 'aktif'" data-placement="top" class="edit-icon" title="Barang keluar">
                            <i class="fas fa-sign-out-alt" data-v-ad6b22ae="" style="font-size: 20px;"></i>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";
import Datepicker from "vue3-date-time-picker";
// import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import { checkRules, cksClient, showAlert } from "../../../../helper";
import { get_KategoriBarangList } from "../../../../actions/barang/kategori";
import { get_BarangDetail, post_BarangSave, post_BarangPengeluaranSewaOut } from "../../../../actions/barang";
import { get_ListDivisi, get_SatuanList } from "../../../../actions/master";
import { maska } from "maska";

import $ from "jquery";
import { DATA } from "../../../../constans/Url";
import moment from "moment";
import "moment/locale/id";
import { VMoney } from "v-money";
import { get_ListVendor } from "../../../../actions/vendor";
import { get_AkunList } from "../../../../actions/akun_perkiraan";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
    // TableLite
  },

  directives: {
    maska,
    money: VMoney,
  },

  data() {
    return {
      isEditable: 1,
      optionUnit: [],
      optionCategory: [],
      optionVendor: [],
      optionAkunRental: [],
      optionDepartement: [],
      optionType: [
        { id: "perlengkapan", text: "Perlengkapan" },
        { id: "peralatan", text: "Peralatan" },
        { id: "persediaan", text: "Persediaan" },
      ],
      id_company: cksClient().get("_account").id_company,
      accessDepartement: cksClient().get("_account").akses_departement,
      //FormData
      isSubmit: false,
      formData: {
        kode: this.$route.params.kode ? this.$route.params.kode : "",
        nama: "",
        nama_satuan: "",
        stock_awal: "",
        satuan: "",
        keterangan: "",
        id_divisi: "",
        kategori: "",
        jenis: "",
        status: "aktif",
        barcode: "",
        pagu: "",
        id_company: cksClient().get("_account").id_company,
        akun_barang: "",
        akun_sewa: "",
      },
      rules: {
        kode: {
          required: false,
        },
        nama: {
          required: true,
        },
        satuan: {
          required: true,
        },
        stock_awal: {
          required: false,
        },
        nama_satuan: {
          required: false,
        },
        kategori: {
          required: true,
        },
        jenis: {
          required: true,
        },
        pagu: {
          required: true,
          min: 1,
          msg_min: "Inputan ini tidak boleh nol",
        },
        keterangan: {
          required: false,
        },
        akun_barang: {
          required: true,
        },
        akun_sewa: {
          required: false,
        },
      },
      formError: [],
      formBarang: {
        name: "",
      },
      //Invtory
      listInventory: [],
      totalInventory: 0,
      tableInventory: "",
      //PO
      listPO: [],
      tablePO: "",
      //LOKASI
      listLokasi: [],
      tableLokasi: "",

      //vendor barang
      configMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      dataTableVendor: "",
      listVendor: [],
      formVendor: {
        id_vendor: "",
        nama_vendor: "",
        harga: "",
        satuan_jumlah: "",
        satuan_nama: "",
      },
      formErrorVendor: [],
      rulesVendor: {
        id_vendor: {
          required: true,
        },
        nama_vendor: {
          required: true,
        },
        harga: {
          required: true,
        },
        satuan_jumlah: {
          required: true,
        },
        satuan_nama: {
          required: true,
        },
      },

      //sewa
      tableSewa: '',
      listSewa: [],
      isLoadSewa: false,
      errorSewa: '',
      formSewa: {
        id: '',
        tanggal_keluar: '',
        nama_gudang: '',
        nama_karyawan: '',
        tanggal_terima: '',
        qty: ''
      },
    };
  },

  mounted() {
    this.tooltip();
    setTimeout(() => {
      $("#pills-home-tab").tab("show");
    }, 600);

    // this.setTableVendor();
  },

  unmounted() {
    $("#pills-home-tab").tab("show");
  },

  created() {
    this.getSatuan();
    this.getCategory();
    this.getVendor();
    this.getAkun();
    this.getDivisi();
    this.getAkunSewa();
    if (this.formData.kode) {
      this.getData();
    } else {
      showAlert(this.$swal, {
        title: "PERHATIAN!",
        msg: "Kode barang tidak ditemukan",
        showCancelButton: false,
        showConfirmButton: false,
        onDismiss: () => {
          this.$router.push({
            name: "DataGoods",
          });
        },
      });
    }
  },

  beforeUnmount() {
    if (this.dataTableVendor) {
      this.dataTableVendor.destroy();
      this.dataTableVendor = "";
    }

    if (this.tableHistory) {
      this.tableHistory.destroy();
      this.tableHistory = "";
    }

    if (this.tablePO) {
      this.tablePO.destroy();
      this.tablePO = "";
    }

    if (this.tableLokasi) {
      this.tableLokasi.destroy();
      this.tableLokasi = "";
    }
    if (this.tableSewa) {
      this.tableSewa.destroy();
      this.tableSewa = "";
    }
  },

  methods: {
    showGoodsVendor() {
      this.formVendor.id_vendor = "";
      this.formVendor.harga = "";
      this.formVendor.satuan_jumlah = "";
      this.formVendor.satuan_nama = "";
      $("#modalGoodsVendor").modal("show");
    },

    getDivisi() {
      get_ListDivisi({ id_company: this.id_company }, (res) => {
        var { list } = res;
        this.optionDepartement = list;
      });
    },

    getAkun() {
      var id_divisi =
        this.accessDepartement && this.formData.id_divisi
          ? this.formData.id_divisi
          : "";
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: "3",
          id_divisi: id_divisi,
        },
        (res) => {
          var data = [];
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }
          this.optionAkunGoods = data;
        }
      );
    },

    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-4" : state.sub_level == 1 ? "pl-3" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },

    getAkunSewa() {
      var id_divisi =
        this.accessDepartement && this.formData.id_divisi
          ? this.formData.id_divisi
          : "";
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: "13,14,15",
          id_divisi: id_divisi,
        },
        (res) => {
          var data = [];
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }
          this.optionAkunRental = data;
        }
      );
    },

    setTableVendor() {
      if (this.dataTableVendor) {
        this.dataTableVendor.destroy();
      }
      setTimeout(() => {
        this.dataTableVendor = $("#tableListVendor").DataTable({
          info: false,
          order: [],
        });
      }, 500);
    },

    getVendor() {
      get_ListVendor(
        {
          id_company: this.id_company,
        },
        (res) => {
          var list = res.list;
          this.optionVendor = [];
          for (const i in list) {
            if (Object.hasOwnProperty.call(list, i)) {
              const element = list[i];
              this.optionVendor.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
        }
      );
    },

    editable() {
      this.isEditable = 2;
    },
    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },
    applyPO() {
      this.$router.push({
        name: "InsertPO",
        params: {
          kode_barang: this.formData.kode,
        },
      });
    },
    formatDate(string, format = "DD MMMM YYYY") {
      return moment(string).format(format);
    },
    //getDetail Barang
    getData() {
      if (this.tablePO) {
        this.tablePO.destroy();
        this.tablePO = '';
      }
      if (this.tableHistory) {
        this.tableHistory.destroy();
        this.tableHistory = '';
      }
      if (this.tableLokasi) {
        this.tableLokasi.destroy();
        this.tableLokasi = ''
      }

      if (this.tableSewa) {
        this.tableSewa.destroy();
        this.tableSewa = ''
      }
      get_BarangDetail(this.formData.kode, (res) => {
        var { data, inventory, datapo, lokasi, total_stock, listVendor, sewa } = res;
        delete data.supplier;
        delete data.created_at;
        delete data.updated_at;
        this.formData = data;
        this.listInventory = inventory;
        this.listPO = datapo;
        this.listLokasi = lokasi;
        this.totalInventory = total_stock;
        this.listVendor = listVendor;
        this.listSewa = sewa;
        this.setTableVendor();
        setTimeout(() => {
          this.tableHistory = $("#tableHistory").DataTable({
            info: false,
            order: [],
          });
          this.tablePO = $("#tablePO").DataTable({
            info: false,
            order: [],
          });
          this.tableLokasi = $("#tableLocation").DataTable({
            info: false,
            order: [],
          });
          this.tableSewa = $("#tableSewa").DataTable({
            info: false,
            order: [],
          });
          this.tooltip();
        }, 500);
      });
    },

    //barangVendor
    async barangChange(key, val) {
      if (this.rulesVendor[key]) {
        this.rulesVendor[key].changed = true;
      }
      if (key === "id_vendor") {
        this.formVendor.nama_vendor = val.text;
        // this.formBarang.id_kategori = val.kategori;
      }
      var check = await checkRules(this.rulesVendor, this.formVendor);
      this.formErrorVendor = check.error;
    },

    async saveBarang() {
      for (const key in this.rulesVendor) {
        if (Object.hasOwnProperty.call(this.rulesVendor, key)) {
          this.rulesVendor[key].changed = true;
        }
      }

      var check = await checkRules(this.rulesVendor, this.formVendor);
      this.formErrorVendor = check.error;
      if (check.success) {
        this.listVendor.push({
          id_vendor: this.formVendor.id_vendor,
          nama_vendor: this.formVendor.nama_vendor,
          harga: this.formVendor.harga.toString().split(".").join(""),
          satuan_jumlah: this.formVendor.satuan_jumlah,
          satuan_nama: this.formVendor.satuan_nama,
        });
        this.setTableVendor();
        $("#modalGoodsVendor").modal("hide");
      }
    },
    resultVendor(val) {
      var check = this.searchObjectVendor(val.id) ? true : false;
      if (!check) {
        return val.text;
      } else {
        return null;
      }
    },

    searchObjectVendor(id) {
      return this.listVendor.find((element) => element.id_vendor == id);
    },

    deleteBarang(key) {
      this.listVendor.splice(key, 1);
      this.setTableVendor();
    },

    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    //MASTER
    getSatuan() {
      get_SatuanList((res) => {
        var { list } = res;
        this.optionUnit = [
          {
            id: "new",
            text: "Satuan Baru",
          },
        ];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            this.optionUnit.push({
              id: element.id,
              text: element.nama,
            });
          }
        }
      });
    },
    getCategory() {
      get_KategoriBarangList(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          var { list } = res;
          this.optionCategory = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionCategory.push({
                id: element.kode,
                text: element.nama,
              });
            }
          }
        }
      );
    },

    async formChange(key, val = "") {
      this.rules[key].changed = true;
      var check = await checkRules(this.rules, this.formData);
      if (key === "satuan" && val.id == "new") {
        this.rules.nama_satuan.required = true;
      } else if (key === "satuan") {
        this.rules.nama_satuan.required = false;
      }
      this.formError = check.error;
    },

    //submit
    postData() {
      this.isSubmit = true;
      var data = this.formData;
      data.vendorBarang = this.listVendor;
      post_BarangSave(
        data,
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "BERHASIL",
            msg: "Data barang berhasil dicatat",
            onSubmit: () => {
              this.$router.push({
                name: "DataGoods",
              });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi Kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.kode
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },

    showImage(image) {
      return DATA.BARANG(image);
    },
    barangOut(item) {
      $('[data-toggle="tooltip"]').tooltip("hide");
      this.formSewa.id = item.id;
      this.formSewa.nama_gudang = item.nama_gudang;
      this.formSewa.nama_karyawan = item.nama_karyawan;
      this.formSewa.qty = item.qty;
      this.formSewa.tanggal_terima = this.formatDate(item.tanggal_terima);
      this.formSewa.tanggal_keluar = ''

      $('#modalGoodsOut').modal('show');
    },

    format(date) {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    },

    changeDate(data, key) {
      if (data) {
        this.errorSewa = '';
        this.formSewa[key] = moment(data).format("YYYY-MM-DD");
      } else {
        this.errorSewa = 'Inputan ini harus anda isi';
      }
    },
    setBarangOut() {
      if (this.formSewa.tanggal_keluar) {
        this.errorSewa = '';
        this.isLoadSewa = true;
        post_BarangPengeluaranSewaOut(this.formSewa, (res) => {
          this.isLoadSewa = false;
          $('#modalGoodsOut').modal('hide');
          if (res.is_success) {
            var find = this.listSewa.findIndex((element) => element.id == this.formSewa.id);
            this.listSewa[find].tanggal_keluar = this.formSewa.tanggal_keluar;
            this.listSewa[find].status = 'tidak';
          }
        })
      } else {
        this.errorSewa = 'Inputan ini harus anda isi';
      }
    }
  },
};
</script>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.card-table {
  position: relative;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}

.img-barcode {
  position: absolute;
  right: 34px;
  top: 20px;
  max-width: 100px;
}
.edit-icon {
  text-align: center;
}

.edit-icon i:hover {
  color: #68caf8;
}
.edit-icon i {
  color: #898989;
  cursor: pointer;
}
</style>
